export const ROOT_ENDPOINT_SOCKETS = 'wss=https://origin-dev.lk-s.com/'
export const ROOT_ENDPOINT = 'https://origin-dev.lk-s.com/'
export const MEDIA_AWS_S3_URL = 'https://lks-bucket-origin-dev-static.s3.eu-west-3.amazonaws.com/'
export const MEDIA_AWS_BUCKET_NAME = 'luckysit-dev'
// Authentication
export const CREATE_TOKEN_ENDPOINT = 'auth/token/'
export const SOCIAL_LOGIN_ENDPOINT = 'auth/oauth/login/'
export const REGISTER_ENDPOINT = 'users/'
export const RESET_PASSWORD_ENDPOINT = 'auth/password-reset/'
export const RESET_PASSWORD_CONFIRM_ENDPOINT = 'auth/password-reset/confirm/'

// Account
export const FETCH_PROFILE_ENDPOINT = 'users/me/'

// I18n
export const FETCH_LANGUAGES_ENDPOINT = 'langs/'

// GAMES
export const GAME_SETTINGS_ENDPOINT = 'places/:slug/events-games'

// Live
export const LIVE_SOCKET = 'live'

// Quizz
export const LUCKY_QUIZZ_SOCKETS = 'lucky-quizz'
export const LUCKY_QUIZZ_RANKING = 'events-games/:id/lucky-quizz/ranking'
export const LUCKY_QUIZZ_FULL_RANKING = 'events-games/:id/lucky-quizz/full-ranking'
export const LUCKY_QUIZZ_USER_GAIN = 'events-games/:id/lucky-quizz/win-gain'
export const LUCKY_QUIZZ_GUEST_GAIN = 'events-games/:id/lucky-quizz/win-gain-as-guest'

// Score
export const EVENT_GAME_RANKING = 'events-games/:id/ranking'
export const EVENT_GAME_FULL_RANKING = 'events-games/:id/full-ranking'
export const WIN_GAIN = 'gains/win'
export const WIN_ONE_GAIN = 'gains/win-one'
export const GAIN_REMOVE_RESERVATION = 'gains/remove-reservation'
