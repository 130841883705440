// input component
import React, { Fragment, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { InputChangeEventDetail, IonInputCustomEvent, TextFieldTypes } from '@ionic/core'
import { IonInput } from '@ionic/react'

import './Input.scss'

interface Props {
  handleInputChangeCallback: (event: IonInputCustomEvent<InputChangeEventDetail>) => void
  name: string
  value?: string
  label: string
  placeholder: string
  type?: TextFieldTypes
  error?: string
}

const Input: React.FC<Props> = ({ handleInputChangeCallback, name, value, label, placeholder, type, error }: Props) => {
	useEffect(() => {
		/* console.log('Input')
		console.log(type) */
		return () => {}
	}, [])
	return (
		<Fragment>
			<label>
				<FormattedMessage id={label} />
			</label>
			{ type !== 'email' ? <FormattedMessage id={placeholder}>
				{msg => (
					<IonInput
						type={type}
						name={name}
						placeholder={msg as unknown as string}
						value={value}
						onIonChange={handleInputChangeCallback}
					/>
				)}
			</FormattedMessage>
				: <IonInput
					type={type}
					name={name}
					placeholder={placeholder}
					value={value}
					onIonChange={handleInputChangeCallback}
				/>
			}
			<div className='input-error'>{error}</div>
		</Fragment>
	)
}

Input.defaultProps = {
	type: 'text'
}

export default Input
