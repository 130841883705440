import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { AppContainer } from 'react-hot-loader'
import { setupIonicReact, isPlatform } from '@ionic/react'
import '@ionic/react/css/core.css'
import './index.scss'
import store from './store'
import { history } from './store/middlewares/router'
import AppRouting from './app/AppRouting'
import * as serviceWorker from './serviceWorker'
import { BaseProvider } from './context/BaseContext'
import setupLocatorUI from '@locator/runtime'
const debug = false
const getConfig = () => {
    if (isPlatform('hybrid')) {
        return {
            swipeBackEnabled: false,
            hardwareBackButton: false
        }
    }
    if (debug) {
        setupLocatorUI()
    }
    return {
        swipeBackEnabled: false
    }
}
setupIonicReact(getConfig())

ReactDOM.render(
    <AppContainer>
        <Provider store={store}>
            <BaseProvider>
                <ConnectedRouter history={history}>
                    <AppRouting />
                </ConnectedRouter>
            </BaseProvider>
        </Provider>
    </AppContainer>,
    document.getElementById('root')
)

if (module.hot) {
    module.hot.accept()
}

serviceWorker.unregister()
