export enum RequestStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected'
}

export const ADS_URL = 'https://adapi.smartadserver.com/4477/call'
export const ADS_FORMAT_HOME = '118170'
export const ADS_FORMAT_HOME_PREMIUM = '132732'
export const ADS_FORMAT_HOME_ANIM = '130391'
export const ADS_FORMAT_HOME_ANIM_PREM = '132736'
export const ADS_FORMAT_PROFITE_PREMIUM = '118171'
export const ADS_FORMAT_PROFITE_ICI_PREMIUM = '118172'
export const ADS_FORMAT_ICI = '118173'
export const ADS_FORMAT_AILLEURS_PREMIUM = '118174'
export const ADS_FORMAT_AILLEURS = '118175'
