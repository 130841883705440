// lemonpie compoenent for tarteaucitron.js
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useBase } from '../../../context/BaseContext'
import ads from '../../../storage/ads.storage'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
// LemonPie component for tarteaucitron.js

interface ILemonPieSettings {
  /** Privacy policy url */
  privacyUrl: string
  /** or top to bring it as first element for accessibility */
  bodyPosition: string
  /** Open the panel with this hashtag */
  hashtag: string
  /** Cookie name */
  cookieName: string
  /* Banner position (top - bottom - middle - popup) */
  orientation: 'top' | 'bottom' | 'middle' | 'popup'
  /** Group services by category */
  groupServices: boolean
  /** Default state (true - wait - false) */
  serviceDefaultState: 'true' | 'wait' | 'false'
  /** Show the small banner on bottom right */
  showAlertSmall: boolean
  /** Show the cookie list */
  cookieslist: boolean
  /** Show a close X on the banner */
  closePopup: boolean
  /** Show the cookie icon to manage cookies */
  showIcon: boolean
  /** Optionnal: URL or base64 encoded image */
  iconSrc?: string
  /** BottomRight, BottomLeft, TopRight and TopLeft */
  iconPosition: 'BottomRight' | 'BottomLeft' | 'TopRight' | 'TopLeft'
  /** Show a Warning if an adblocker is detected */
  adblocker: boolean
  /** Show the deny all button */
  DenyAllCta: boolean
  /** Show the accept all button when highPrivacy on */
  AcceptAllCta: boolean
  /** HIGHLY RECOMMANDED Disable auto consent */
  highPrivacy: boolean
  /** If Do Not Track == 1, disallow all */
  handleBrowserDNTRequest: boolean
  /** Remove credit link */
  removeCredit: boolean
  /** Show more info link */
  moreInfoLink: boolean
  /** If false, the tarteaucitron.css file will be loaded */
  useExternalCss: boolean
  /** If false, the tarteaucitron.js file will be loaded */
  useExternalJs: boolean
  /** Shared cookie for multisite */
  cookieDomain?: string
  /** Change the default readmore link */
  readmoreLink: string
  /** Show a message about mandatory cookies */
  mandatory: boolean
  /** Show the disabled accept button when mandatory on */
  mandatoryCta: boolean
}

const defaultSettings: ILemonPieSettings = {
	privacyUrl: '',
	bodyPosition: 'bottom',
	hashtag: '#tarteaucitron',
	cookieName: 'tarteaucitron',
	orientation: 'middle',
	groupServices: false,
	serviceDefaultState: 'wait',
	showAlertSmall: false,
	cookieslist: false,
	closePopup: false,
	showIcon: true,
	iconPosition: 'BottomLeft',
	adblocker: true,
	DenyAllCta: true,
	AcceptAllCta: true,
	highPrivacy: true,
	handleBrowserDNTRequest: false,
	removeCredit: false,
	moreInfoLink: true,
	useExternalCss: false,
	useExternalJs: false,
	readmoreLink: '',
	mandatory: true,
	mandatoryCta: true
}

interface Props {
  isApp?: boolean
  language?: string
  analyticId: string
  settings?: Partial<ILemonPieSettings>
}

const LemonPie: React.FC<Props> = ({ isApp, analyticId, settings = {}, language = 'fr' }) => {
	const [consentStored, setConsentStored] = useState('no')

	useEffect(() => {
		setTimeout(() => {
			(async () => {
				const stored = await getConsent()
				setConsentStored(stored)
				// setConsent(stored)
				if (stored === 'no') {
					console.log('enable FirebaseAnalytics')
					await FirebaseAnalytics.setCollectionEnabled({ enabled: false })
				} else {
					if (isApp) {
						await FirebaseAnalytics.setCollectionEnabled({ enabled: true })
					}
				}
			})()
		}, 1000)
	}, [isApp])

	const getConsent = async () => {
		return await ads.getConsent()
	}
	return !isApp ? (
		<Helmet>
			<script async type='text/javascript'>
				{`
               // console.log('tarte au citron init')
                var tarteaucitronForceLanguage = '${language}';
                tarteaucitron.init(${JSON.stringify({ ...defaultSettings, ...settings })});
            `}
			</script>
			<script async type='text/javascript'>
				{`
				tarteaucitron.user.gtagUa = '${analyticId}';
                // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];
                tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
                (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
			`}
			</script>
		</Helmet>
	) : null
}

export default LemonPie
