// url listener used for deep linking and analytics
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { messages } from '../../../locale'
import { getPlatforms } from '@ionic/react'
import { gtagEvent } from '../../../utils/gtagEvent'

interface Props {
    language?: string
}
// AppUrlListener is a component that listens to the appUrlOpen event for deep linking and analytics
// TODO: automatically get the event and call api
const AppUrlListener: React.FC<Props> = ({ language }) => {
    const history = useHistory()
    const location = useLocation()
    const platform = getPlatforms()
    const pathList = [
        {
            translateKey: 'HOME_PAGE',
            path: '/home/'
        },
        {
            translateKey: 'HOME_PAGE',
            path: '/'
        },
        {
            translateKey: 'LOGIN_PAGE',
            path: '/login/'
        },
        {
            translateKey: 'NOT_CONNECTED_PAGE',
            path: '/qr/'
        },
        {
            translateKey: 'EVENT_INFO',
            path: '/home/event/'
        },
        {
            translateKey: 'TIR_AU_BUT',
            path: '/home/tir-au-but/'
        },
        {
            translateKey: 'ROUE_FORTUNE',
            path: '/home/roue-de-la-fortune/'
        },
        {
            translateKey: 'QUIZZ',
            path: '/home/quizz/'
        },
        {
            translateKey: 'EARNINGS_PAGE',
            path: '/earnings/'
        },
        {
            translateKey: 'PROFILE_PAGE',
            path: '/profile/'
        },
        {
            translateKey: 'RESULT_PAGE',
            path: '/result/'
        }
    ]
    useEffect(() => {
        const path = location.pathname
        /* console.log('setting screenName for path', path)
		console.log('location', location) */
        const currentPath = pathList.filter((p) => p.path === path)[0]
        if (currentPath) {
            /* console.log({ currentPath })
			console.log(messages[language][currentPath.translateKey].META.TITLE) */
            const params = {
                page_title: messages[language][currentPath.translateKey].META.TITLE,
                page_location: currentPath
            }
            if (platform.includes('hybrid')) {
                FirebaseAnalytics.logEvent({ name: 'page_view', params }).then((gtag) => console.log({ gtag }))
            } else {
                gtagEvent('page_view', params)
            }
        } else if (path.includes('/home/place/')) {
            const params = {
                page_title: 'Place',
                page_location: currentPath
            }
            if (platform.includes('hybrid')) {
                FirebaseAnalytics.logEvent({ name: 'page_view', params }).then((gtag) => console.log({ gtag }))
            } else {
                gtagEvent('page_view', params)
            }
        }
    }, [location])

    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            if (event.url) {
                if (event.url.includes('com.lk-s.luckysit://home')) {
                    const slug = event.url.split(':/')[1]
                    // set to ios
                    history.replace('/')
                } else if (event.url.includes('luckysit://com.lks.luckysit')) {
                    // set to android
                    history.replace('/')
                } else {
                    // history.push(event.url)
                }
            }
            // If no match, do nothing - let regular routing
            // logic take over
        })
    }, [])

    return null
}

export default AppUrlListener
