// consent modal for GDPR android
import React, { useEffect, useRef } from 'react'
import { IonButton, IonModal, IonTitle } from '@ionic/react'
import { FormattedMessage } from 'react-intl'
import { useBase } from '../../../context/BaseContext'
import './ConsentModal.scss'

interface Props {
  children: React.ReactNode
  enabled?: boolean
}
// consent modal for GDPR android
const ConsentModal: React.FC<Props> = (props) => {
	const { consent, setConsent, showConsentModal, setShowConsentModal } = useBase()
	const shouldDisplay = props.enabled && consent !== 'yes'
	const modalRef = useRef<HTMLIonModalElement>(null)
	const onSetConsent = async (value: 'yes' | 'no') => {
		await setConsent(value)
		setShowConsentModal(false)
	}
	useEffect(() => {
		// console.log('showConsentModal', showConsentModal)
	}, [showConsentModal])
	return (
		<>
			{props.children}
			{
				<IonModal id='consent-modal' ref={modalRef} isOpen={showConsentModal} backdropDismiss={false} showBackdrop={true}>
					<div className='modal-wrapper'>
						<div className='modal-header'>
							<h2 className='modal-header-title'>
								<FormattedMessage id='CONSENT_MODAL.TITLE' />
							</h2>
						</div>
						<div className='modal-content'>
							<p>
								<FormattedMessage id='CONSENT_MODAL.CONTENT' />
							</p>
							<div className='modal-buttons'>
								<IonButton fill='clear' onClick={() => onSetConsent('yes')}>
                  Autoriser
								</IonButton>
								<IonButton fill='clear' onClick={() => onSetConsent('no')}>
                  Refuser
								</IonButton>
							</div>
						</div>
					</div>
				</IonModal>
			}
		</>
	)
}

export default ConsentModal
