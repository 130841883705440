import React, { createContext, useContext, useReducer, ReactNode } from 'react'
import ads from '../storage/ads.storage'
import appConfig from '../storage/config.storage'
import { IEvent } from '../types/IEvent'
import { TTabSelector } from '../types/TTabSelector'

interface IBaseContext {
    event?: IEvent
    consent?: 'yes' | 'no' | 'unknown'
    currentTab?: TTabSelector
    currentAddsTab?: 'here' | 'around'
    showConsentModal?: boolean
    showLangModal?: boolean
    lang?: string
}
const initialState: IBaseContext = {
    event: undefined,
    consent: 'no',
    currentTab: 'home',
    currentAddsTab: 'here',
    showConsentModal: false,
    showLangModal: false,
    lang: 'fr'
}
// context reducer user to update context state for all components that use it (tabs, consent modal, lang modal..)
function reducer(state: IBaseContext, action: { type: string; payload?: Record<string, any> }): IBaseContext {
    switch (action.type) {
        case 'set-event':
            return { ...state, event: action.payload?.event }
        case 'set-consent':
            return { ...state, consent: action.payload?.consent }
        case 'set-current-tab':
            return { ...state, currentTab: action.payload?.currentTab }
        case 'set-current-adds-tab':
            return { ...state, currentAddsTab: action.payload?.currentAddsTab }
        case 'set-show-consent-modal':
            return { ...state, showConsentModal: action.payload?.showConsentModal }
        case 'set-show-lang-modal':
            return { ...state, showLangModal: action.payload?.showLangModal }
        case 'set-lang':
            return { ...state, lang: action.payload?.lang }
        default:
            throw new Error()
    }
}

// eslint-disable-next-line func-call-spacing
const BaseContext = createContext<[IBaseContext, (type: string, payload?: Record<string, any>) => void]>([
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {}
])
export const BaseProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [data, dispatchData] = useReducer(reducer, initialState)

    return <BaseContext.Provider value={[data, (t, p) => dispatchData({ type: t, payload: p })]}>{children}</BaseContext.Provider>
}

export const useBase = () => {
    const [context, dispatch] = useContext(BaseContext)
    /**
     * Should ONLY be called once AND ONLY after IonicStorageModule is initialized
     */
    const initFromStorage = async () => {
        // const consent = await ads.getConsent()
        const eventConfig = await appConfig.getEvent()
        /* if (consent) {
			dispatch('set-consent', { consent })
		} else {
			dispatch('set-consent', { consent: 'unknown' })
		} */
        if (eventConfig) {
            dispatch('set-event', { event: eventConfig })
        }
    }
    const setEvent = (event: IEvent) => {
        dispatch('set-event', { event })
    }
    const setConsent = async (consent: 'yes' | 'no' | 'unknown') => {
        await ads.setConsent(consent)
        dispatch('set-consent', { consent })
    }
    const setShowConsentModal = (showConsentModal: boolean) => {
        dispatch('set-show-consent-modal', { showConsentModal })
    }
    const setShowLangModal = (showLangModal: boolean) => {
        dispatch('set-show-lang-modal', { showLangModal })
    }
    const setLang = (lang: string) => {
        dispatch('set-lang', { lang })
    }
    return {
        initFromStorage,
        event: context.event,
        setEvent,
        consent: context.consent,
        setConsent,
        setShowConsentModal,
        showConsentModal: context.showConsentModal,
        setShowLangModal,
        showLangModal: context.showLangModal,
        setLang,
        lang: context.lang
    }
}
export const useBaseAdds = () => {
    const [context, dispatch] = useContext(BaseContext)
    const setCurrentAddsTab = (currentAddsTab: 'here' | 'around') => {
        dispatch('set-current-adds-tab', { currentAddsTab })
    }
    return { currentAddsTab: context.currentAddsTab, setCurrentAddsTab }
}

export const useBaseTab = () => {
    const [context, dispatch] = useContext(BaseContext)
    const setCurrentTab = (currentTab: TTabSelector) => {
        dispatch('set-current-tab', { currentTab })
    }
    return { currentTab: context.currentTab, setCurrentTab }
}
