import React, { useEffect, useState, useRef } from 'react'
import './MenuLang.scss'
import { useBase } from '../../../context/BaseContext'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { fetchLanguages, updateLanguage } from '../../../store/features/i18n/i18n.slice'
import CheckIcon from '@mui/icons-material/Check'
import userStorage from '../../../storage/user.storage'
import { IonButton } from '@ionic/react'
import { Button } from '../../components'
import appConfig from '../../../storage/config.storage'
import { userService } from '../../../services/user.service'
import { appService } from '../../../services/settings.service'
import { gameServices } from '../../../services/game.service'

interface Props {
}

const LangModal: React.FC<Props> = () => {
	const dispatch = useAppDispatch()
	const [currentLang, setCurrentLang] = useState('fr')
	const [IsShowinModal, setIsShowinModal] = useState(true)
	const { showLangModal, setShowLangModal } = useBase()
	const { initFromStorage, setLang, lang } = useBase()
	const onUpdateLanguage = (language: string) => dispatch(updateLanguage(language))
	const [isGuest, setIsGuest] = useState(true)

	useEffect(() => {
		(async () => {
			setTimeout(async () => {
				const cLang = await userStorage.getLang()
				if (cLang) {
					setCurrentLang(cLang)
				}
			}, 500)
		 })()
	}, [showLangModal])
	const getUser = async () => {
		const currentUser = await userStorage.getUser()
		setIsGuest(currentUser.email.includes('-guest@luckysit.com'))
		userService.me().then((me) => {
			const loggedUser = me.data
			userStorage.setUser(loggedUser)
			setIsGuest(me.data.email.includes('-guest@luckysit.com'))
		})
	}
	const handleChangeLang = async (lang: string) => {
		setCurrentLang(lang)
		userStorage.getUser()
		const userStored = await userStorage.getUser()
		setIsGuest(userStored.email.includes('-guest'))
	}

	const validateLang = async () => {
		userStorage.setLang(currentLang)
		setLang(currentLang)
		onUpdateLanguage(currentLang)
		setShowLangModal(false)
		const adsId = await appConfig.getAdsConfig()
		const config = await appConfig.getEvent()
		const site = await appConfig.getSite()
		const slug = site.slug
		if (!isGuest) {
			getUser()
			if (config.slug) {
				const settings = await appService.getSettings(slug, adsId.pageId)
				const games = await gameServices.getSettings(slug)
				appConfig.setGames(games)
				appConfig.setEvent(settings.event)
				appConfig.setSite(settings)
			}
		} else {
			console.log('not needed /me call')
			if (config.slug) {
				if (config.slug) {
					const settings = await appService.getSettings(slug, adsId.pageId)
					const games = await gameServices.getSettings(slug)
					appConfig.setGames(games)
					appConfig.setEvent(settings.event)
					appConfig.setSite(settings)
				}
			}
		}
	}

	const closeModal = () => {
		setShowLangModal(false)
	}

	return (
		<>
			{
				showLangModal
					? <div className='overlay'>
						<div className="modal-lang">
							<h1 className='title'><FormattedMessage id={'LANG_MODAL.TITLE'} /></h1>
							<div className="lang-list">
								<ul className='list'>
									<li className={
										'lang ' +
									(currentLang.includes('fr') ? 'active' : '')	}
									onClick={() => handleChangeLang('fr')}
									>
										<FormattedMessage id={'LANG_MODAL.FRENCH'} />
										<div className="circle">
											<CheckIcon />
										</div>
									</li>
									<li className={
										'lang ' +
									(!currentLang.includes('fr') ? 'active' : '')	}
									onClick={() => handleChangeLang('en')}
									>
										<FormattedMessage id={'LANG_MODAL.ENGLISH'} />
										<div className="circle">
											<CheckIcon />
										</div>
									</li>
								</ul>
							</div>
							<div className='modal-buttons'>
								<Button
									className="button annulation"
									color='transparent'
									id="LANG_MODAL.MODAL_BUTTON_1"
									onClick={closeModal}
								/>
								<Button
									className="button"
									color='#DA261B'
									id="LANG_MODAL.MODAL_BUTTON_2"
									onClick={validateLang}
								/>
							</div>
						</div>
					</div>
					: false

			}
		</>
	)
}

export default LangModal
