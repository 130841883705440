// language dropdown
import React from 'react'
import { useHistory } from 'react-router-dom'
import { IonSelect, IonSelectOption } from '@ionic/react'

import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { RootState } from '../../../store'
import { updateLanguage } from '../../../store/features/i18n/i18n.slice'
import { handleRedirection } from '../../../utils/redirection'
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core'

interface Props {
  currentPage: string
  language: string
}

const LanguageDropdown: React.FC<Props> = ({ currentPage, language }: Props) => {
	const dispatch = useAppDispatch()
	const history = useHistory()

	const languages: Array<string> = useAppSelector((state: RootState) => state.i18n.languages)

	const onUpdateLanguage = (language: string) => dispatch(updateLanguage(language))

	const handleChangeLanguage = (event: IonSelectCustomEvent<SelectChangeEventDetail>): void => {
		const { target: { value } } = event

		if (value === language) return

		onUpdateLanguage(value)
		handleRedirection(currentPage, history, value)
	}

	return (
		<IonSelect value={language} onIonChange={handleChangeLanguage}>
			{languages.length && languages.map((language, i) => (
				<IonSelectOption key={i} value={language[0]}>{language[1]}</IonSelectOption>
			))}
		</IonSelect>
	)
}

export default LanguageDropdown
