// timer component
import React, { useState, useEffect } from 'react'
import Chrono from '../../../assets/icons/timer.png'
import ChronoStress from '../../../assets/icons/timer-stress.png'
import './GameChronometer.scss'

interface Props {
  startValue: number
  getTimeLeft: (val:number) => void
  bgColor?: string
  timerColorBase?: string
  timerColorHighlight?: string
  reset?: boolean
  gameStarted: boolean
}

const GameChronometer: React.FC<Props> = ({ startValue, getTimeLeft, bgColor, timerColorBase, timerColorHighlight, reset, gameStarted }: Props) => {
	const [counter, setCounter] = useState(startValue)
	const [milliCounter, setMilliCounter] = useState(startValue - 1)

	useEffect(() => {
		if (gameStarted) {
			if (counter > 0) {
				const timeout = setTimeout(() => {
					setCounter(counter - 1)
					getTimeLeft(counter - 1)
				},
				1000)
				return () => clearTimeout(timeout)
			}
		}
	}, [gameStarted, counter])

	useEffect(() => {
		if (reset) {
			setCounter(startValue)
			setMilliCounter(startValue - 1)
		}
	}, [reset])

	useEffect(() => {
		if (milliCounter > 0) {
			const mlcounter = setTimeout(() => {
				setMilliCounter(Math.round((milliCounter - 0.1) * 100) / 100)
			}, 100)
			return () => clearTimeout(mlcounter)
		}
	}, [milliCounter, reset])

	return (
		<div className="game-chronometer">
			<div className={`animation-background ${(((counter * 100) / startValue) / 100) <= 0.25 && 'last-seconds'}`}
				style={{
					transform: 'rotate(' + (((milliCounter * 100) / startValue) / 100) + 'turn)',
					background: `conic-gradient(${timerColorHighlight},${timerColorBase})`
				}}>
			</div>
			<div className="chronometer-background" style={{ background: bgColor }} >
				<div className="chrono-icon">
					{(((counter * 100) / startValue) / 100) <= 0.25
						? <img src={ChronoStress}/>
						: <img src={Chrono}/>
					}
				</div>
				<div className="text-wrapper">
					{counter}
				</div>
			</div>
		</div>
	)
}

GameChronometer.defaultProps = {
	startValue: 15,
	bgColor: 'linear-gradient(91.74deg, #1D3FFF 6.18%, #132487 97.94%)',
	timerColorBase: '#16328F',
	timerColorHighlight: '#ffffff'
}

export default GameChronometer
