// routing file for the app
import React, { useEffect, useState, Fragment } from 'react'
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import loadable from '@loadable/component'
import { IonApp, IonRouterOutlet, getPlatforms, IonTab } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { messages } from '../locale'
import { useAppDispatch, useAppSelector } from '../hooks/store'
import { RootState } from '../store'
import { tokenVerification } from '../store/features/login/login.slice'
import { fetchLanguages, updateLanguage } from '../store/features/i18n/i18n.slice'
import { RequestStatus } from '../config/constants'
import { createStore, get, set, clear } from '../utils/IonicStorage'
import { SplashScreen } from '@capacitor/splash-screen'
import NotFound from './pages/NotFound/NotFound'
import { Helmet, HelmetProvider, HelmetData } from 'react-helmet-async'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { useBase } from '../context/BaseContext'
import ConsentModal from './components/ConsentModal/ConsentModal'
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation'
import { Keyboard } from '@capacitor/keyboard'
import { Device } from '@capacitor/device'
import rotate from '../assets/rotate-screen.webp'
import LemonPie from './components/LemonPie/LemonPie'
import AppUrlListener from './components/AppUrlListener/AppUrlListener'
import userStorage from '../storage/user.storage'
import { userService } from '../services/user.service'
import ads from '../storage/ads.storage'
import LangModal from './components/LangSwitcher/LangModal'
import { log } from 'console'
const Quizz = loadable(() => import('./pages/Games/Quizz/Quizz'))
const NotConnected = loadable(() => import('./pages/HomeNotConnected/HomeNotConnected'))
const TabsBar = loadable(() => import('./components/TabsBar/TabsBar'))
const Seat = loadable(() => import('./pages/Seat/Seat'))
const Login = loadable(() => import('./pages/Login/Login'))
const Register = loadable(() => import('./pages/Register/Register'))
const ResetPassword = loadable(() => import('./pages/ResetPassword/ResetPassword'))
const ResetPasswordConfirm = loadable(() => import('./pages/ResetPasswordConfirm/ResetPasswordConfirm'))
const EventDetail = loadable(() => import('./pages/EventDetail/EventDetail'))
const Penalty = loadable(() => import('./pages/Games/Penalty/Penalty'))
const RoueDeLaFortune = loadable(() => import('./pages/Games/RoueDeLaFortune/RoueDeLaFortune'))
const Home = loadable(() => import('./pages/Home/Home'))
const LandingPage = loadable(() => import('./pages/LandingPage/LandingPage'))

declare global {
    interface Navigator {
        browserLanguage?: string
    }
}

// ionic react router
const AppRouting: React.FC = () => {
    const dispatch = useAppDispatch()
    const { initFromStorage, setLang, lang } = useBase()
    const [isApp, setIsApp] = useState(false)
    const language: 'en' | 'fr' | 'es' = useAppSelector((state: RootState) => state.i18n.language)
    const languagesStatus: string = useAppSelector((state: RootState) => state.i18n.status)
    const onUpdateLanguage = (language: string) => dispatch(updateLanguage(language))
    const onTokenVerification = () => dispatch(tokenVerification())
    const onFetchLanguages = () => dispatch(fetchLanguages())
    const [isPortrait, setIsPortrait] = useState(true)
    const { showConsentModal, setShowConsentModal } = useBase()
    const history = useHistory()
    const platform = getPlatforms()
    const location = useLocation()

    /**
     *  check if user logged or login user
     * @returns User
     */
    const loginUser = () => {
        return new Promise<any>(async (resolve, reject) => {
            const user = await userStorage.getUser()
            if (user === null) {
                const userToken = await userService.loginGuest()
                // console.log(userToken)
                if (userToken.data) {
                    userStorage.setToken(userToken.data.accessToken)
                    const loggedUser = userToken.data.user
                    userStorage.setUser(loggedUser)
                    resolve(loggedUser)
                }
            } else {
                resolve(user)
            }
        })
    }

    /**
     *  function to lock screen orientation
     */
    useEffect(() => {
        SplashScreen.hide()

        if (platform.includes('hybrid')) {
            Keyboard.addListener('keyboardWillShow', (info) => {
                // console.log('keyboard will show with height:', info.keyboardHeight)
            })

            Keyboard.addListener('keyboardDidShow', (info) => {
                // console.log('keyboard did show with height:', info.keyboardHeight)
            })

            Keyboard.addListener('keyboardWillHide', () => {
                // console.log('keyboard will hide')
            })

            Keyboard.addListener('keyboardDidHide', () => {
                // console.log('keyboard did hide')
            })
            lock()
        }
        onTokenVerification()
        // check if is app or not to load tarteaucitron and force portrait
        if (platform.includes('hybrid')) {
            setIsApp(true)
        } else {
            setIsApp(false)
            setTimeout(() => {
                window.tarteaucitron.load()
                if (window.screen.orientation) {
                    setIsPortrait(window.screen.orientation.type.includes('portrait'))
                    window.screen.orientation.onchange = function (e) {
                        setIsPortrait(window.screen.orientation.type.includes('portrait'))
                    }
                } else {
                    const mql = window.matchMedia('(orientation: portrait)')
                    // If there are matches, we're in portrait
                    if (mql.matches) {
                        setIsPortrait(true)
                    } else {
                        setIsPortrait(false)
                    }
                    // Add a media query change listener
                    mql.addListener(function (m) {
                        if (m.matches) {
                            setIsPortrait(true)
                        } else {
                            setIsPortrait(false)
                        }
                    })
                }
            }, 1000)
        }
        // setup store using localforage
        const setupStore = async () => {
            await createStore('LuckysitDB')
            await initFromStorage()
            const localLang = await userStorage.getLang()
            const deviceLang = await Device.getLanguageCode()
            console.log({ deviceLang })
            console.log({ localLang })
            if (!localLang) {
                if (platform.includes('hybrid')) {
                    const deviceLang = await Device.getLanguageCode()
                    if (deviceLang.value) {
                        userStorage.setLang(deviceLang.value)
                        setLang(deviceLang.value)
                        onUpdateLanguage(deviceLang.value)
                    }
                } else {
                    const langNavigator = navigator.language || navigator.browserLanguage
                    if (langNavigator) {
                        if (langNavigator.includes('-')) {
                            userStorage.setLang(langNavigator.split('-')[0])
                            setLang(langNavigator.split('-')[0])
                            onUpdateLanguage(langNavigator.split('-')[0])
                        } else {
                            userStorage.setLang(langNavigator)
                            setLang(langNavigator)
                            onUpdateLanguage(langNavigator)
                        }
                    }
                }
            } else {
                if (localLang.includes('-')) {
                    userStorage.setLang(localLang.split('-')[0])
                    setLang(localLang.split('-')[0])
                    onUpdateLanguage(localLang.split('-')[0])
                } else {
                    userStorage.setLang(localLang)
                    setLang(localLang)
                    onUpdateLanguage(localLang)
                }
            }
            // console.log({ 'android check': platform })
            if (platform.includes('hybrid') && platform.includes('android')) {
                const consent = await ads.getConsent()

                if (!consent || consent !== 'yes') {
                    setShowConsentModal(true)
                }
            }
        }

        setupStore()
    }, [])

    // set screen orientation
    const lock = async () => {
        await ScreenOrientation.lock({ type: OrientationType.PORTRAIT })
    }

    return (
        <HelmetProvider>
            <IntlProvider locale={lang} messages={flatten(messages[language])}>
                <IonApp>
                    <>
                        <LemonPie isApp={isApp} analyticId='G-WL1Q8MWBDQ' />
                        <ConsentModal enabled={platform.includes('android') && platform.includes('hybrid')}>
                            <IonReactRouter>
                                <AppUrlListener language={language}></AppUrlListener>
                                <IonRouterOutlet>
                                    <Route
                                        path={[
                                            `/${messages[language].LANDING_PAGE.META.URL}`,
                                            `/${messages[language].LANDING_PAGE.META.URL}/:slug/:siteId/:pageId`,
                                            `/${messages[language].LANDING_PAGE.META.URLV2}`,
                                            `/${messages[language].LANDING_PAGE.META.URLV2}/:slug/:siteId/:pageId`
                                        ]}
                                        exact
                                        component={() => <LandingPage currentPage='LANDING_PAGE' language={language} />}
                                    />
                                </IonRouterOutlet>

                                <TabsBar isApp={isApp} language={language} />
                            </IonReactRouter>
                        </ConsentModal>
                        {!isPortrait ? (
                            <div className='no-landscape'>
                                <img className='rotate-image' src={rotate} alt='' />
                            </div>
                        ) : null}
                    </>
                    <LangModal />
                </IonApp>
            </IntlProvider>
        </HelmetProvider>
    )
}

export default AppRouting
