// button component
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { IonButton } from '@ionic/react'
import './Button.scss'
import appConfig from '../../../storage/config.storage'
import { useBase } from '../../../context/BaseContext'

interface Props {
    id: string
    onClick: () => void
    className?: string
    disabled?: boolean
    color?: string
}

const Button: React.FC<Props> = ({ id, onClick, className, disabled, color }: Props) => {
    const { event } = useBase()
    return (
        <IonButton
            onClick={onClick}
            shape='round'
            expand='block'
            style={{ '--background': color || event?.colorButton }}
            className={`lucky-button ${className}`}
            disabled={disabled}
        >
            <FormattedMessage id={id} />
        </IonButton>
    )
}

Button.defaultProps = {
    id: 'SHARED.BUTTON',
    onClick: () => {},
    disabled: false
}

export default Button
