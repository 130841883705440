// close button component
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IonButton, IonImg } from '@ionic/react'
import closeIcon from '../../../assets/icons/close.svg'
import './ButtonClose.scss'

interface Props {
  id?: string
  onClick: () => void
}

const ButtonClose: React.FC<Props> = ({ id, onClick }: Props) => {
	return (
		<IonButton onClick={onClick} className="button-close">
			<IonImg src={closeIcon}/>
		</IonButton>
	)
}

ButtonClose.defaultProps = {
	id: 'SHARED.BUTTON',
	onClick: () => {}
}

export default ButtonClose
