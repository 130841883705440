import React from 'react'
import GoogleLogin from 'react-google-login'

import { Button } from '../'

interface Props {
  onSocialLoginCallback: (platform: string, accessToken: string) => void
}

const GoogleButton: React.FC<Props> = ({ onSocialLoginCallback }: Props) => {
	const handleGoogleLogin = (response: { accessToken: string }): void => {
		onSocialLoginCallback('google-oauth2', response.accessToken)
	}

	return (
		<GoogleLogin
			clientId={process.env.GOOGLE_LOGIN_ID}
			onSuccess={() => handleGoogleLogin}
			onFailure={() => handleGoogleLogin}
			render={(renderProps: { onClick: () => {} }) => (
				<Button
					id='SHARED.GOOGLE_BUTTON'
					onClick={renderProps.onClick}
				/>
			)}
		/>
	)
}

export default GoogleButton
